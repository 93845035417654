.previousEpisodes {
  max-width: var(--maxWidth);
  margin: 0 auto;
  padding: 20px var(--sidePadding);

 display: grid;
 gap: 1rem;
 grid-template-columns: 1fr;
}

.episodeCard {
  padding: var(--sidePadding);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.episodeCard a {
  color: black;
}

.cardContent {
  position: relative;
}

.bgClr1 {
  background-color: var(--clr1)
}
.bgClr2 {
  background-color: var(--clr2)
}
.bgClr3 {
  background-color: var(--clr3)
}

.epPic {
  position: relative;
  min-height: 30px;

}
.epPic img {
  width: 100%;
}

.epDate {
  position: absolute;
  top: 0;
  left: 0;
  font-family: var(--font-cond-head);
  font-size: 1.2rem;
  text-transform: uppercase;
  padding:0 1rem .2rem 0;
  border-bottom-right-radius: 7px;
}


.epTitle {
  font-family: var(--font-cond-head);
  font-size: 1.8rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1em;
}

.episodeCard audio {
  border-radius: 40px;
  width: 100%;

}

.previousEpisodes .timeDuration {
  display: block;
}

@media (min-width: 480px) AND (max-width: 580px) {
  .previousEpisodes .timeDuration {
    display: none;
  }
}

@media (min-width: 480px) {
  .previousEpisodes {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 960px) {
  .previousEpisodes {
    grid-template-columns: 1fr 1fr 1fr;
  }
}