.footercontainer{
  font-family: var(--font-sans-body);
  height: 60px;
  background-color: var(--paleGrey);
  display: grid;
  place-items: center;
  grid-row-start: 2;
  grid-row-end: 3;

}
.footercontainer--copy{
  font-size: 14px;
  display: block;
  text-align: center;
  color: var(--midGrey);
}
