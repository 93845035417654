.Header {
  box-sizing: border-box;
  overflow: hidden;
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  padding: 0 var(--sidePadding);
  transition: all .5s;
}
.Header.hidden{
  transform: translateY(-56px);
}

.Header .headerIcons {
  display: flex;
  align-items: center;
  /* gap: 10px; */
}

.headerIcons a {
  display: block;
  height: 35px;
}

.Header .allSocial {
  display: flex;
  align-items: center;
}
.Header .stuffLogoHeader {
  display: none;
}
.Header .stuffIconHeader {
  display: block;
}

@media (min-width: 440px) {
  .Header .stuffLogoHeader {
    display: block;
  }
  .Header .stuffIconHeader {
    display: none;
  }
}