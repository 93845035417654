.introBanner {
  background-color: var(--clr1);
  padding: .3rem var(--sidePadding);

}
.introBanner-inner {
  max-width: var(--maxWidth);
  margin: 0 auto;
  padding: 1rem;
  display: grid;
  grid-template-areas:
  'tagline'
  'blurb'
  'sponsor'
  ;
  gap: .5rem;
}

.introBanner-inner .tagLine {
  font-family: var(--font-cond-head);
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1em;
  grid-area: tagline;
}

.introBanner-inner .introText {
  font-size: 1.2rem;
  text-align: center;
  font-weight: 300;
  grid-area: blurb;
}

.introBanner-inner .introText  a {
  color: #000000;
  text-decoration: none;
  font-weight: 500;
}
.introBanner-inner .introText  a:hover, .introBanner-inner .introText  a:hover   {
  text-decoration: underline;
}


.introBanner-inner .sponsorLogo {
  grid-area: sponsor;
  text-align: center;
  margin-top: 15px;
}

@media(min-width: 720px) {
  .introBanner-inner {
    grid-template-areas:
    'tagline blurb'
    'sponsor blurb'
    ;
    gap: 0rem 2rem;
  }
  .introBanner-inner .tagLine {
    text-align: left;
  }
  .introBanner-inner .introText {
    text-align: left;
  }
  .introBanner-inner .sponsorLogo {
    text-align: left;
  }

}

@media (min-width: 960px) {
  .introBanner-inner {
    grid-template-areas:
      'tagline taglinr blurb blurb blurb sponsor'
  }

  .introBanner-inner .sponsorLogo {
    margin-top: 0;

  }
}

@media (min-width: 1200px) {
  .introBanner-inner .tagLine {
    font-size: 4rem;
  }
}