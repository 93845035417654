.fullWidthBack {
  background-color: black;
  padding-top: 60px;
}
.topSection {
  display: grid;
  max-width: var(--maxWidth);
  margin: 0 auto;
  padding: var(--topPadding) 0 0;
  gap: 1rem;
  grid-template-areas:
    "splash"
    "hero"
    "latest";
}

.splashArea {
  grid-area: splash;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.latestArea {
  grid-area: latest;
  width: 100%;
  place-self: end;
}
.heroArea {
  grid-area: hero;
  place-self: end;
}

@media (min-width: 960px) {
  .fullWidthBack {
    padding: 60px 0 0;
  }

  .topSection {
    padding: 4rem auto 3rem;
    grid-template-columns: 1fr 1fr 2fr;
    grid-template-areas:
      "splash splash hero hero"
      "latest latest hero hero";
  }
}
