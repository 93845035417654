.previousOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 0;
}

.previousOptions .imageOption {
  display: flex;
  align-items: center;
  color: black;
  cursor: pointer;
}

.previousOptions .imageOption span{
  font-size: .875rem;
  text-transform: uppercase;
}

.imageOptionBtn {
  width: 25px;
  height: 25px;
  border: 2px solid black;
  border-radius: 50%;
  margin-left: .3em;
  position: relative;
}

.imageShowBtn {
  background-color: white;
  color: black;
}
.imageShowBtn::after {
  content: '+';
  font-size: 2rem;
  font-weight: 500;
  line-height: 1rem;
  position: absolute;
  top: 2px;
  left: 1px;
}

.imageHideBtn {
  background-color: black;
  color: white;
}

.imageHideBtn::after {
  content: '-';
  font-size: 2rem;
  font-weight: 500;
  line-height: 1rem;
  position: absolute;
  top: 0px;
  left: 3px;
}

