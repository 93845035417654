#animated_newsable {
  max-width: 80%;
}

#dot1 {
  animation: dot1 3s linear infinite;
}
#dot2 {
  animation: dot2 3s linear infinite;
}
#dot3 {
  animation: dot3 3s linear infinite;
}

@keyframes dot1 {
  0% {opacity: 0}
  20% {opacity: 1}
  80% {opacity: 1}
  100% {opacity: 0}
}
@keyframes dot2 {
  0% {opacity: 0}
  10% {opacity: 0}
  40% {opacity: 1}
  80% {opacity: 1}
  100% {opacity: 0}
}
@keyframes dot3 {
  0% {opacity: 0}
  20% {opacity: 0}
  50% {opacity: 1}
  80% {opacity: 1}
  100% {opacity: 0}
}

@media (min-width: 960px) {
  #animated_newsable {
    max-width: 100%;
  }
}