.volume {
  position: absolute;
  display: flex;
  align-items: center;
  background-color: white;
  right: 30px;
  padding: 4px 10px 2px;
  border: 1px solid black;
  border-radius: 30px;
  z-index: 5;
}

.volume .audioUp {
  margin-left: 6px;
}

.volume .volProgress {
  display: flex;
  align-items: center;
}

.volume .volProgressBar {
  --bar-bg: #9a9a9a;
  --bar-height: 5px;
  --seek-before-volWidth: 0;
  --seek-before-color: #000000;
  --handle: #23e146;
  --selectedHandle: #23e146;

  appearance: none;
  background: var(--bar-bg);
  border-radius: 10px;
  position: relative;
  margin-top: -1px;
  width: 100%;
  height: var(--bar-height);
  outline: none;
}
/* progress bar safari */
.volProgressBar::-webkit-slider-runnable-track {
  background: var(--bar-bg);
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: var(--bar-height);
  outline: none;
}

/* progress bar - Firefox */
.volProgressBar::-moz-range-track {
  background: var(--bar-bg);
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: var(--bar-height);
  outline: none;
}

.volProgressBar::-moz-focus-outer {
  border: 0;
}

/* progrss bar - chrome and safari */
.volProgressBar::before {
  content: '';
  height: var(--bar-height);
  width: var(--seek-before-volWidth);
  background-color: var(--seek-before-color);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 12;
  cursor:pointer;
}

/* progress bar - firefox */
.volProgressBar::-moz-range-progress {
  background-color: var(--seek-before-color);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: var(--bar-height);
}

/* handle - chrome and safari */
.volProgressBar::-webkit-media-slider-thumb, .volProgressBar::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1px solid #23e146;
  background-color: var(--handle) !important;
  cursor: pointer;
  position: relative;
  margin: -5px 0 0 0;
  z-index: 15;
  box-sizing: border-box;
}
/* while dragging */
.volProgressBar:active::-webkit-slider-thumb {
  transform: scale(1.2);
  background: var(--selectedHandle);
}

/* handle - firefox */
.volProgressBar::-moz-range-thumb {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: transparent;
  background-color: var(--handle);
  cursor: pointer;
  position: relative;
  z-index: 3;
  box-sizing: border-box;
}
.volProgressBar:active::-moz-range-thumb {
  transform: scale(1.2);
  background: var(--selectedHandle);
}

/* exclude for ios as volume control via javascript is blocked */

@supports (-webkit-touch-callout: none) {
  .volume {
    display: none;
  }
}