html, body {
  padding: 0;
  margin: 0;
}

body>img {
  display: none;
}

:root {
  --font-sans-body: "Stuff Sans", Helvetica, Arial, sans-serif;
  --font-cond-head: "Stuff Sans Condensed", Tahoma, Arial, sans-serif;
  --hotPink: #ff71ee;
  --neonGreen: #23e146;
  --paleGrey: #f5f5f5;
  --lightGrey: #e2e2e2;
  --midGrey: #737373;

  --clr1: var(--hotPink);
  --clr2: var(--neonGreen);
  --clr3: var(--lightGrey);

  --sidePadding: 1rem;
  --SidePadding2: 2rem;

  --maxWidth: 1200px;
  --topPadding: 0;


}

#root {
  min-height: 100vh;
  min-height: 100dvh;
  display: grid;
  grid-template-rows: 1fr auto;
}

@media screen and (min-width: 1600px) and (min-height: 900px ) {
  :root {
    --maxWidth: 1400px;
    --topPadding: 40px;
  }

}