.allFollow {
  width: fit-content;
  margin: 20px auto;
  /* padding: 20px var(--sidePadding); */
  text-align: center;
  border:1px solid black;
  border-radius: 40px;
  padding: .5rem 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.followText {
  font-family: var(--font-cond-head);
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 600;

}

.followIcons {
  display: flex;
  gap: 20px;
  align-items: center;
  line-height: 0em;
}

.followIcon:hover {
  opacity: .5;
}

@media (min-width: 300px) {
  .allFollow {
    flex-direction: row;
    padding: .5rem 1.5rem;
    align-items: center;
  }
  .followText {
    margin-right: .5rem;
  }
}