.podcastPlatforms {
  color: #ffffff;
  font-weight: 300;
  font-size: 0.875rem;
  margin: 1rem 0;
}

.podcastPlatforms span {
  margin-right: 1rem;
}

.podcastPlatforms a:not(:last-of-type) {
  margin-right: 1rem;
}
