.ads-new-wrap-container {
  margin: 40px 0;
  text-align: center;
  display: none;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
  p.ads-new-wrap-container-p {
    color: #999;
    font-family: 'Stuff Sans', Roboto, sans-serif;
    font-size: 10.4px;
    line-height: 16.6px !important;
    margin-bottom: 5px;
    text-align: center;
    text-transform: uppercase;
  }

  .ads-new-wrap-container > div {
    padding: 8px 0;
  }
  a.ads-new-wrap-container-a {
    color: #999;
    margin-top: 5px;
    font-family: 'Stuff Sans', Roboto, helvetica, arial, sans-serif;
    font-size: 11.2px;
    font-style: italic;
    text-align: right;
    text-decoration: none;
  }

div.ads-new-wrap-container.active {
  display: flex;
}