.podcastCard {
  border: 2px solid var(--clr3);
  border-radius: 10px;
  padding: 1rem;
}

.podcastCard img {
  width: 100%;
}

.podcastCard .otherPodAuthor {
  font-weight: 300;
  font-size: .875rem;

}

.podcastCard .otherPodTitle {
  font-weight: 600;
  font-size: 1.1rem;

}

.otherPodsList a {
  text-decoration: none;
  color: black;
  display: block;
  height: 100%;
}
.otherPodsList a:hover {
  opacity: .8;
}