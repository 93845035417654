.previousBanner {
  background-color: var(--clr2);
  padding: .6rem var(--sidePadding);
  display: flex;
  justify-content: center;
  align-items: center;
}
.previousBanner .previousBtn {
  color: black;
  border: 2px solid black;
  background-color: transparent;
  padding: 0 2rem;
  border-radius: 50px;
  font-family: var(--font-cond-head);
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: uppercase;
}

.previousBtn .btnarrow {
  display: inline-block;
  width: 9px;
  margin-left: .5em;
}