.podcast-latest {
  background-color: black;
  padding: 0 var(--sidePadding);

  min-height: 100px;
}

.podcast-latest audio {
  border-radius: 40px;
  margin: 0 auto;
}

.podcast-latest-image {
  /* uncomment following lines for cropped image */
  /* aspect-ratio: 16/9;
  height: 100%;
  overflow: hidden; */
  display: flex;
  width: 100%;
  align-items: center;
}
.podcast-latest-image img {
  max-width: 100%;
  height: auto;
  aspect-ratio: 1;
  object-fit: cover;

}
.podcast-latest-info {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 3px;
  color: white;
}

.podcast-latest-pubdate {
  font-size: .875rem;
  font-weight: 300;
  line-height: 1em;
}

.podcast-latest-info h2 {
  font-weight: 600;
  font-size: 2.8rem;
  font-family: var(--font-cond-head);
  text-transform: uppercase;
  line-height: 1em;
  margin: 0;
  padding: 0;
}
.podcast-latest-info h3 {
  font-weight: 600;
  margin: 0;
  padding: 0;
}
.podcast-latest-info h4 {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: .9rem;
  text-transform: uppercase;
}

.podcast-latest-info p {
  font-weight: 300;
  font-size: 1.2rem;
}
.podcast-latest-info a {
  color: white;
  text-decoration: none;
  font-weight: 500;
}
.podcast-latest-info a:hover {
  text-decoration: underline;
}