.audioPlayer {
  position: relative;
  display: flex;
  align-items: center;
  gap: 7px;
  border: 1px solid black;
  border-radius: 50px;
  padding: 3px 15px 3px 10px;
  background-color: rgba(255, 255, 255, 1);
  /* width: fit-content; */
  justify-content: space-between;
  color: black;
  @media (min-width: 960px) {
    width: 60%;
  }
}

.audioPlayer .playPause {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
}
/* .audioPlayer .playPause:hover, .audioPlayer .playPause:focus {
  background-color: var(--paleGrey);
} */

.audioPlayer .playPause .play {
  position: relative;
  left: 1px;
}

.audioPlayer .playPause .pause {
  position: relative;
  right: 1px;
}

.audioPlayer .progressBar {
  --bar-bg: #9a9a9a;
  --bar-height: 5px;
  --seek-before-width: 0;
  --seek-before-color: #000000;
  --handle: #ff71ee;
  --selectedHandle: #ff71ee;

  appearance: none;
  background: var(--bar-bg);
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: var(--bar-height);
  outline: none;
}

.audioPlayer .time {
  font-family: "Azeret Mono", monospace;
  font-weight: 300;
  font-size: 12px;
}

.progress {
  display: flex;
  align-content: center;
  flex-shrink: 1;
  flex-grow: 1;
}

/* progress bar safari */
.progressBar::-webkit-slider-runnable-track {
  background: var(--bar-bg);
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: var(--bar-height);
  outline: none;
}

/* progress bar - Firefox */
.progressBar::-moz-range-track {
  background: var(--bar-bg);
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: var(--bar-height);
  outline: none;
}

.progressBar::-moz-focus-outer {
  border: 0;
}

/* progrss bar - chrome and safari */
.progressBar::before {
  content: "";
  height: var(--bar-height);
  width: var(--seek-before-width);
  background-color: var(--seek-before-color);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
}

/* progress bar - firefox */
.progressBar::-moz-range-progress {
  background-color: var(--seek-before-color);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: var(--bar-height);
}

/* handle - chrome and safari */
.progressBar::-webkit-media-slider-thumb,
.progressBar::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1px solid #ff71ee;
  background-color: var(--handle) !important;
  cursor: pointer;
  position: relative;
  margin: -5px 0 0 0;
  z-index: 3;
  box-sizing: border-box;
}
/* while dragging */
.progressBar:active::-webkit-slider-thumb {
  transform: scale(1.2);
  background: var(--selectedHandle);
}

/* handle - firefox */
.progressBar::-moz-range-thumb {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: transparent;
  background-color: var(--handle);
  cursor: pointer;
  position: relative;
  z-index: 3;
  box-sizing: border-box;
}
.progressBar:active::-moz-range-thumb {
  transform: scale(1.2);
  background: var(--selectedHandle);
}

.audioPlayer .vertDots {
  position: relative;
  transform: translateY(1px);
  margin-left: 10px;
}

/* exclude for ios as volume control via javascript is blocked */

@supports (-webkit-touch-callout: none) {
  .audioPlayer .vertDots {
    display: none;
  }
}
