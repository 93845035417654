.splashWrapper {
  margin-left: var(--sidePadding);
  position: relative;
}
.newsableSplash {
  display: flex;
  justify-content: center;
  margin: 40px 0 0;
}

.newsableSplash svg {
  width: 100%;
}

@media (min-width: 720px) {
  .splashWrapper {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    margin-bottom: 0;
  }
}

@media (min-width: 960px) {
  .splashWrapper {
    grid-template-columns: 1fr 1fr;
    margin: 0 0 1rem;
  }
  .newsableSplash {
    align-self: end;
    margin: 10px 0 0;
  }
}

.badge {
  background: #222;
  border-radius: 50%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  margin: 20px 0;
}
.badge::before {
  box-shadow: 0 0 0 0.7em #e3d100;
  border-radius: 50%;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform-origin: 0 0;
  transform: translate(-50%, -50%);
}
.badge__award {
  height: 25px;
  width: auto;
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
  font-size: 9px;
  color: #fff;
  white-space: nowrap;
  text-align: center;
}
.badge__title {
  background: #e3d100;
  display: block;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.85;
  position: relative;
  margin: 0 -1em 0.25em;
  max-width: 11em;
  text-transform: uppercase;
  width: 11em;
  z-index: 2;
  white-space: nowrap;
  text-align: center;
  line-height: 1.2;
  font-family: "Stuff Sans", Arial, Helvetica, sans-serif;
  font-weight: 500;
  padding: 5px 0;
  box-sizing: border-box;
  top: 20px;
}
.badge__title::before,
.badge__title::after {
  border-width: 1.3em 0.5em;
  border-style: solid;
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 0.25em;
}
.badge__title::before {
  border-color: #e3d100 #e3d100 #e3d100 transparent;
  left: -1.12em;
}
.badge__title::after {
  border-color: #e3d100 transparent #e3d100 #e3d100;
  right: -1.1em;
}
