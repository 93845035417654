.otherPodcasts {
  max-width: var(--maxWidth);
  margin: 0 auto;
}

.otherPodsList {
  padding: 0 var(--sidePadding) 30px ;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
}

.otherPodcasts a {
  color: black;
  text-decoration: none;
}
.otherPodcasts a:hover, .otherPodcasts a:focus {
  text-decoration: underline;
}


@media (min-width: 480px) {
  .otherPodsList {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 960px) {
  .otherPodsList {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}