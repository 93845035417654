.loadingAnimation {
  display: flex;
  justify-content: center;
  align-items: center;

}

.loadingDots{
  position: relative;
  width: 70px;
  text-align: center;
}

.loadingDots > div {
  width: 18px;
  height: 18px;
  background-color: var(--clr2);

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.loadingDots .ldots-bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loadingDots .ldots-bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}