
.App {
  font-family: var(--font-sans-body);

}

.App * {
  box-sizing: border-box;
}

main {
  /* padding-bottom: 60px 0; */
  margin: 0;
  padding: 0;
}

h3.centerHead {
  font-family: var(--font-cond-head);
  font-size: 2rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
  line-height: 1em;
  margin: 1em 0 .5em 0;
}

h4.leftHead {
  font-family: var(--font-cond-head);
  font-size: 1.5rem;
  text-transform: uppercase;
  text-align: left;
  font-weight: 500;
  margin: 0;
}